import { useCallback, useEffect, useRef, useState } from "react"

export function usePersist<T>(initialValue: T, persistMethod: () => Promise<void>, persistInterval = 5 * 60 * 1000) {
    const ref = useRef(initialValue);
    const [loading, setLoading] = useState(false);

    const updateValue = useCallback((newValue: T) => {
        ref.current = newValue;
    }, []);


    // autosave
    useEffect(() => {
        const interval = setInterval(async () => {
            // save state
            setLoading(true);
            await persistMethod();
            setLoading(false);
        }, persistInterval);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { value: ref.current, updateValue, loading }
}

function abortablePersistMethod<T>(value: T, persistMethod: (value: T) => Promise<void>, signal: AbortSignal) {
    return new Promise<void>((resolve, reject) => {
        persistMethod(value)
            .then(() => resolve());
        signal.addEventListener(('abort'), () => {
            reject();
        })
    })
}

export function usePersistWithAbortController<T>(initialValue: T, persistMethod: (value: T) => Promise<void>, persistInterval = 15 * 60 * 1000) {
    const ref = useRef(initialValue);

    const updateValue = useCallback((newValue: T) => {
        ref.current = newValue;
    }, []);

    // autosave
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const interval = setInterval(async () => {
            await abortablePersistMethod(ref.current, persistMethod, signal);
        }, persistInterval);

        return () => { controller.abort(); clearInterval(interval) };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { value: ref.current, updateValue }
}
