import { WS } from "@lex/lex-types";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import dayjs from 'dayjs';

const intentionalCloseCode = 3069;

const connectToWs = (
    url: string,
    onMessage: <T>(e: MessageEvent<T>) => void,
    onOpen?: (e: Event) => void,
    onError?: (e: Event) => void,
    onClose?: (e: CloseEvent) => void,
    onHB?: () => void,
    onUnintentionalDisconnect?: () => void,
) => {
    let sock = new WebSocket(url);
    sock.onopen = e => onOpen?.(e);
    sock.onmessage = e => {
        const parsedMessage: WS.WebSocketMessage = JSON.parse(e.data);
        switch (parsedMessage.event) {
            case WS.EventTypes.HEARTBEAT:
                onHB?.();
                break;
            default:
                onMessage(e)
        }
    }
    sock.onerror = e => {
        console.error(e);
        onError?.(e);
    };
    sock.onclose = (e => {
        if (e.code !== intentionalCloseCode) {
            console.log('reconnecting');
            onUnintentionalDisconnect?.();
            setTimeout(() => {
                sock = connectToWs(url, onMessage, onOpen, onError, onClose, onUnintentionalDisconnect);
            }, 5000);
        } else {
            console.log('wtf')
            onClose?.(e);
        }
    });
    return sock;
}

const useWebSocket = (
    url: string,
    docId: string,
    onMessage: <T>(e: MessageEvent<T>) => void,
    onOpen?: (e: Event) => void,
    onError?: (e: Event) => void,
    onClose?: (e: CloseEvent) => void,
    onUnintentionalDisconnect?: () => void,
) => {
    const { decodedToken } = useContext(UserContext);

    const [sock, setSock] = useState<WebSocket | undefined>();

    // TODO: These should be called from the main context
    const notifyOpenDocument = useCallback((docId: string) => {
        if (sock && decodedToken) {
            sock.send(JSON.stringify({
                event: 'open-document',
                docId,
            }))
        }
    }, [decodedToken, sock]);

    // TODO: These should be called from the main context
    const notifyCloseDocument = useCallback((docId: string) => {
        if (sock && decodedToken) {
            sock.send(JSON.stringify({
                event: 'close-document',
                docId,
            }))
        }
    }, [decodedToken, sock]);

    useEffect(() => {
        if (decodedToken) {
            const newSock = connectToWs(
                url,
                onMessage,
                e => {
                    const message: WS.HandshakeMessage = {
                        event: WS.EventTypes.HANDSHAKE,
                        docId,
                        email: decodedToken.email,
                    }
                    newSock.send(JSON.stringify(message));
                    onOpen?.(e);
                },
                onError,
                onClose,
                () => {
                    // console.log('received HB')
                },
                onUnintentionalDisconnect
            );
            setSock(newSock);
            return () => {
                console.log('closing');
                newSock.close(intentionalCloseCode, 'Page closed');
            }
        }
    }, [decodedToken, onClose, onError, onMessage, onOpen, onUnintentionalDisconnect, url, docId]);

    useEffect(() => {
        const heartbeatIntervalMs = 5 * 1000;
        const heartbeatInterval = setInterval(() => {
            if (sock && sock.OPEN) {
                sock.send(JSON.stringify({ event: WS.EventTypes.HEARTBEAT }));
            }
        }, heartbeatIntervalMs);
        return () => clearInterval(heartbeatInterval);
    }, [sock]);

    return { sock, notifyCloseDocument, notifyOpenDocument };
}

export default useWebSocket;
