export enum EventTypes {
    OVERRIDE_OWNER = 'override-owner',
    HANDSHAKE = 'handshake',
    HEARTBEAT = 'heartbeat',
}

export type BaseMessage = {
    event: EventTypes,
    email: string,
}

export type HandshakeMessage = BaseMessage & {
    event: EventTypes.HANDSHAKE,
    docId: string
}

export type OverrideOwnerMessage = BaseMessage & {
    event: EventTypes.OVERRIDE_OWNER
}

export type HeartbeatMessage = Omit<BaseMessage, 'email'> & {
    event: EventTypes.HEARTBEAT
}

export type WebSocketMessage = HandshakeMessage | OverrideOwnerMessage | HeartbeatMessage
